import React from 'react';
import { Layout } from '../components/shared/Layout';

const AboutPage = (): JSX.Element => {
  return (
    <Layout>
      <main className="">
        <section className="bg-white py-8">
          <div className="container mx-auto max-w-3xl">
            <h1>Over De Haagse Munt</h1>
            <h2>
              De Haagse Munt brengt een andere economie met een lokale munt naar
              Den Haag
            </h2>
            <p>
              Binnen de Haagse Munt betalen we elkaar met een eigen rentevrije
              munt. Een nieuwe (en betere) economie kan alleen plaats vinden als
              we daar bewust voor kiezen. Samen bouwen we samen aan een
              complementair geldsysteem, een lokale marktplaats met de Haagse
              Munt, zonder ingewikkelde organisaties, rente en aandeelhouders.
            </p>
            <h2>De Munt voegt meerdere keren waarde toe in de regio</h2>
            <p>
              Door elkaar met lokaal munt te betalen, betaal je niet alleen voor
              een lokaal product of dienst, je geeft ook je waarden door aan de
              volgende ondernemer of persoon, en die weer aan de volgende. We
              maken de regio letterlijk en figuurlijk rijker. De Munt draagt bij
              aan een zelfredzame samenleving die minder afhankelijk wordt van
              (financiële) invloeden van buitenaf. Open nu een account bij De
              Haagse Munt!
            </p>
            <h2>Duurzame ontwikkeling</h2>
            <p>
              We versterken de economische duurzaamheid. Geldstromen circuleren
              meer en langer binnen de regio. Iedere munt creëert meerdere keren
              waarde binnen de lokale economie. We bevorderen de sociale
              duurzaamheid omdat mensen kunnen deelnemen aan de lokale economie.
              Lokale bedrijven gaan op zoek naar ongebruikte capaciteit. Een
              eigen lokale munt activeert mensen en vergroot de betrokkenheid in
              de samenleving. Ecologische duurzaamheid wordt versterkt door
              lokaal geproduceerde goederen en diensten. Er worden minder
              kilometers gemaakt. We ruilen producten en diensten die bijdragen
              aan een gezonde en duurzame lokale economie voor onszelf en de
              opkomende generaties.
            </p>
            <h2>Organisatie</h2>
            <p>
              De Haagse Munt is een initiatief van urbanlink.nl. De organisatie
              van de munt is op dit moment in oprichting. We starten met het
              uitgeven van fysieke munten in eigen beheer. Gelijktijdig
              ontwikkelen we de digitale munt en bijbehorende app. Via deze app
              kun je straks Haagse Munten betalen en ontvangen. Het budget van
              De Haagse Munt wordt straks beheerd door Stichting Derdengelden De
              Haagse Munt. Deze stichting wordt gecontroleerd door een Raad van
              Toezicht en Accountant. De stichting zorgt ervoor dat het budget
              op de juiste manier wordt beheerd en besteedt. Interesse om bij te
              dragen aan de organisatie van De Haagse Munt? Neem dan contact op!
            </p>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default AboutPage;
